



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { UPDATE_COUNTER_ACCOUNT } from "@/modules/accounting/store";
import { CounterAccount, UpdateCounterAccount } from "@/modules/accounting/types";
import FinancialStateSelect from "@/modules/conservatorship/components/FinancialStateSelect.vue";

const AccountingStore = namespace('accounting');

@Component({
  components: { FinancialStateSelect }
})
export default class UpdateAccountDialog extends Vue {
  @Prop() value!: CounterAccount | null;

  @AccountingStore.Action(UPDATE_COUNTER_ACCOUNT) updateAccount!: (command: UpdateCounterAccount) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  command: UpdateCounterAccount = {
    counter_account_id: '',
    financial_state: '',
    code: ''
  };

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.clear();
    }, 200);
  }

  @Watch('value')
  watchValue(value: Account) {
    if (!value) return;

    this.init();

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  clear() {
    this.command = {
      counter_account_id: '',
      financial_state: '',
      code: ''
    };

    this.error = null;
    this.$emit('input', null);
    // @ts-ignore
    this.$refs.form.reset();
  }

  init() {
    if (!this.value) return;

    this.command = {
      counter_account_id: this.value.id,
      financial_state: this.value.financialState,
      code: this.value.code
    };
  }

  async save(): Promise<ApiResponse> {
    this.error = null;

    return this.updateAccount(this.command);
  }
}
