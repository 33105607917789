







































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse, Pagination } from '@/components/types';
import ErrorAlert from '@/components/ErrorAlert.vue';
import AppTable from '@/components/AppTable.vue';
import { FETCH_ACCOUNTS, GET_ACCOUNTS } from "../store";
import { Account } from "../types";
import CreateAccountDialog from "@/modules/accounting/components/CreateAccountDialog.vue";
import UpdateAccountDialog from "@/modules/accounting/components/UpdateAccountDialog.vue";
import DeleteAccountDialog from "@/modules/accounting/components/DeleteAccountDialog.vue";

const AccountingStore = namespace('accounting');

@Component({
  components: {
    DeleteAccountDialog,
    UpdateAccountDialog,
    CreateAccountDialog,
    AppTable,
    ErrorAlert
  }
})
export default class AccountTable extends Vue {
  @AccountingStore.Getter(GET_ACCOUNTS) list!: Account[];
  @AccountingStore.Action(FETCH_ACCOUNTS) fetch!: () => Promise<ApiResponse<Account[]>>;

  error: any = null;
  loading: boolean = false;
  editAccount: Account | null = null;
  deleteAccount: Account | null = null;

  pagination: Pagination = {
    sortBy: ['code'],
    itemsPerPage: -1
  };

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.court'), value: 'court.name' },
      { align: 'left', text: this.$i18n.t('accounting.code'), value: 'code' },
      { align: 'left', text: this.$i18n.t('common.actions'), value: null, sortable: false, width: '140px' }
    ];
  }

  async created() {
    this.loading = true;
    this.error = null;

    const { error } = await this.fetch();

    this.loading = false;

    if (error) {
      this.error = error;
    }
  }
}
