







































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import uuid from 'uuid/v4';
import { ApiResponse } from '@/components/types';
import CourtSelect from "@/modules/court/components/CourtSelect.vue";
import { CREATE_ACCOUNT } from "@/modules/accounting/store";
import { CreateAccount } from "@/modules/accounting/types";

const AccountingStore = namespace('accounting');

@Component({
  components: { CourtSelect }
})
export default class CreateAccountDialog extends Vue {
  @AccountingStore.Action(CREATE_ACCOUNT) createAccount!: (command: CreateAccount) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;

  court: string = '';
  code: string = '';
  dialog: boolean = false;

  @Watch('dialog')
  watchDialogClosed(dialog: boolean): void {
    if (!dialog) {
      setTimeout(() => {
        this.error = null;
        this.loading = false;
        // @ts-ignore
        this.$refs.form.reset();
      }, 200);
      return;
    }

    this.init();
  }

  clear() {
    this.init();
    this.error = null;
    this.loading = false;
    // @ts-ignore
    this.$refs.form.reset();
    this.dialog = false;
  }

  init() {
    this.court = '';
    this.code = '';
  }

  onError(error: any) {
    this.loading = false;
    this.error = error;
  }

  async save() {
    this.error = null;

    return this.createAccount({
      account_id: uuid(),
      court_id: this.court,
      code: this.code
    });
  }
}
