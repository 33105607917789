























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { DELETE_COUNTER_ACCOUNT } from "@/modules/accounting/store";
import { CounterAccount, DeleteCounterAccount } from "@/modules/accounting/types";

const AccountingStore = namespace('accounting');

@Component({})
export default class DeleteAccountDialog extends Vue {
  @Prop() value!: CounterAccount | null;

  @AccountingStore.Action(DELETE_COUNTER_ACCOUNT) deleteAccount!: (command: DeleteCounterAccount) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.clear();
    }, 200);
  }

  @Watch('value')
  watchValue(value: CounterAccount) {
    if (!value) return;

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  clear() {
    this.error = null;
    this.$emit('input', null);
  }

  async save(): Promise<ApiResponse | void> {
    this.error = null;

    if (!this.value) return;

    return this.deleteAccount({ counter_account_id: this.value.id });
  }
}
