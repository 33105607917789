











import { Component, Vue } from 'vue-property-decorator';
import AccountTable from "@/modules/accounting/components/AccountTable.vue";
import CounterAccountTable from "@/modules/accounting/components/CounterAccountTable.vue";

@Component({
  components: { CounterAccountTable, AccountTable }
})
export default class Overview extends Vue {}
