







































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import uuid from 'uuid/v4';
import { ApiResponse } from '@/components/types';
import { CREATE_COUNTER_ACCOUNT } from "@/modules/accounting/store";
import { CreateCounterAccount } from "@/modules/accounting/types";
import FinancialStateSelect from "@/modules/conservatorship/components/FinancialStateSelect.vue";

const AccountingStore = namespace('accounting');

@Component({
  components: { FinancialStateSelect }
})
export default class CreateCounterAccountDialog extends Vue {
  @AccountingStore.Action(CREATE_COUNTER_ACCOUNT) createAccount!: (command: CreateCounterAccount) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;

  financialState: string = '';
  code: string = '';
  dialog: boolean = false;

  @Watch('dialog')
  watchDialogClosed(dialog: boolean): void {
    if (!dialog) {
      setTimeout(() => {
        this.error = null;
        this.loading = false;
        // @ts-ignore
        this.$refs.form.reset();
      }, 200);
      return;
    }

    this.init();
  }

  clear() {
    this.init();
    this.error = null;
    this.loading = false;
    // @ts-ignore
    this.$refs.form.reset();
    this.dialog = false;
  }

  init() {
    this.financialState = '';
    this.code = '';
  }

  onError(error: any) {
    this.loading = false;
    this.error = error;
  }

  async save() {
    this.error = null;

    return this.createAccount({
      counter_account_id: uuid(),
      financial_state: this.financialState,
      code: this.code
    });
  }
}
